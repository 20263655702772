<template>
	<header-block title="Analysis"></header-block>
	<CustomerLoadWait :customer="customer && spendingAnalysis && spendingPlans" />
	<transition>
		<div v-if="customer && spendingAnalysis && spendingPlans">
			<SpendingAnalysisCategory 
				categoryName="Income" 
				:totalAmount="selectedIncome.totalIn"
				:bgColour="`#B4D8D7`" 
				:formatter="formatter">
				<SpendingAnalysisSubCategory v-for="group in selectedIncome.groupedAnalysis"
					:key="group" 
					:group="group"
					:income="true"
					:bgColour="`#B4D8D7`" 
					:formatter="formatter">
				</SpendingAnalysisSubCategory>
			</SpendingAnalysisCategory>
<br/>
<br/>
			<!-- TODO: don't just pass in the first one!  That will be selected from the month selector -->
			<SpendingAnalysisCategory 
				categoryName="Everyday Spending" 
				:totalAmount="selectedEverydaySpend.totalOut"
				:bgColour="`linear-gradient(251.68deg, #EEF0FF 24.87%, #D4D9F2 72.24%)`" 
				:txtColour="`#000000`"
				:formatter="formatter"
				@toggle="(t) => { toggleEveryDaySpending(t) }">
				<SpendingAnalysisSubCategory v-for="group in selectedEverydaySpend.groupedAnalysis"  
					:key="group" 
					:group="group"
					:plans="spendingPlans"
					:bgColour="`linear-gradient(251.68deg, #EEF0FF 24.87%, #D4D9F2 72.24%)`" 
					:isCollapsed="isEveryDaySpendCollapsed"
					:formatter="formatter">
				</SpendingAnalysisSubCategory>
			</SpendingAnalysisCategory>
<br/>
<br/>
			<SpendingAnalysisCategory
				categoryName="Future plans" 
				:totalAmount="selectedFuturePlans.totalOut"
				:bgColour="`linear-gradient(243.52deg, #EFE8E6 16.63%, #CFC1C0 83.37%)`" 
				:txtColour="`#000000`"
				@toggle="(t) => { toggleFuturePlans(t) }"
				:formatter="formatter">
				<SpendingAnalysisSubCategory v-for="group in selectedFuturePlans.groupedAnalysis"
					:key="group" 
					:group="group"
					:plans="spendingPlans"
					:bgColour="`linear-gradient(243.52deg, #EFE8E6 16.63%, #CFC1C0 83.37%)`" 
					:isCollapsed="isFuturePlansCollapsed"
					:formatter="formatter">
				</SpendingAnalysisSubCategory>
			</SpendingAnalysisCategory>
			<br/>
<br/>
			<SpendingAnalysisCategory 
				categoryName="Committed costs" 
				:totalAmount="selectedCommittedCosts.totalOut"
				:bgColour="`#413655`" 
				:txtColour="`#FFFFFF`"
				@toggle="(t) => { toggleCommittedCosts(t) }"
				:formatter="formatter">
				<SpendingAnalysisSubCategory v-for="group in selectedCommittedCosts.groupedAnalysis"
					:key="group" 
					:group="group"
					:plans="spendingPlans"
					:bgColour="`#413655`"
					:isCollapsed="isCommittedCostsCollapsed"
					:formatter="formatter">
				</SpendingAnalysisSubCategory>
			</SpendingAnalysisCategory>
		</div>
	</transition>
</template>

<script>
import Services from '../mixins/Services';
import Helpers, { GRANDPARENT_CLASSIFICATIONS, SPENDING_ANALYSIS_CATEGORIES, SPENDING_ANALYSIS_KEYS } from '../mixins/Helpers';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import SpendingAnalysisCategory from './elements/SpendingAnalysisCategory.vue';
import SpendingAnalysisSubCategory from './elements/SpendingAnalysisSubCategory.vue';

export default {
	mixins: [Services, Helpers],
	components: {CustomerLoadWait, HeaderBlock, SpendingAnalysisCategory, SpendingAnalysisSubCategory},
	data() {
		return {		
			customer: null,
			isEveryDaySpendCollapsed: true,
			isFuturePlansCollapsed: true,
			isCommittedCostsCollapsed: true,
			months: [],
			years: [],
			slots: [],
			allEveryDaySpend: [],
			allFuturePlans: [],
			allCommittedCosts: [],
			allIncome: [],
			// selectedSlotKey: 'March 2024', // this will be set from the slot selector component
			selectedSlotKey: 'January 2025', // this will be set from the slot selector component
		};
	},
	computed: {
		selectedEverydaySpend() {
			return this.selectedCategory(this.allEveryDaySpend, GRANDPARENT_CLASSIFICATIONS.EVERYDAY_SPEND);
		},
		selectedFuturePlans() {
			return this.selectedCategory(this.allFuturePlans, GRANDPARENT_CLASSIFICATIONS.FUTURE_PLANNING);
		},
		selectedCommittedCosts() {
			return this.selectedCategory(this.allCommittedCosts, GRANDPARENT_CLASSIFICATIONS.FIXED_COSTS);
		},
		selectedIncome() {
			var selected = this.allIncome.find(s => s.slotName === this.selectedSlotKey);
			if (!selected) {
				selected = {
					label: this.selectedSlotKey,
					totalIn: 0,
					totalOut: 0,
					totalNet: 0,
					groupedAnalysis: []
				};
			}
			if (selected.groupedAnalysis.length > 0) {
				selected.groupedAnalysis[0].name = this.customer.employer || 'Employer';
			}
			return selected;
		}
	},
	mounted: function() { 
		this.onLoad(); 
	},
	methods: {
		onLoad: async function() {
			this._getCustomer();
			this._getSpendingAnalysis(() => {
				this.setupCategories();
			});
			this._getSpendingPlansLite();
		},
		selectedCategory(categoryArray, grandparentClassification) {
			var selected = categoryArray.find(s => s.slotName === this.selectedSlotKey) 
			if (!selected) {
				selected = {
					label: this.selectedSlotKey,
					totalIn: 0,
					totalOut: 0,
					totalNet: 0,
					groupedAnalysis: []
				};
			}
			// add any missing groups that we have plans for
			var newGroups = [];
			var plans = this.spendingPlans.plans.filter(plan => plan.classification.grandParentClassification === grandparentClassification);
			plans.forEach(plan => {
				// if this plan isn't found in the analysis, add it
				if (!selected.groupedAnalysis.find(g => g.name === plan.classification.fullName))
				{
					newGroups.push({
						name: plan.classification.fullName,
						transactionClassification: plan.classification,
						amountIn: 0,
						amountOut: 0,
						amountNet: 0,						
					});
				}
			});
			selected.groupedAnalysis = selected.groupedAnalysis.concat(newGroups);
			return selected;
		},
		setupCategories() {
			this.getMonthlyAnalyis();
			this.getAnnualAnalysis();
			this.mergeMonthlyAnnualAnalysis();
			this.getSlots();
			console.log('allEveryDaySpend', this.allEveryDaySpend);
			console.log('slots', this.slots);
		},
		getMonthlyAnalyis() {
			this.monthlyEverydaySpend = this.extractCycledAnalysisCategory(this.spendingAnalysis, SPENDING_ANALYSIS_CATEGORIES.EVERYDAY_SPEND, SPENDING_ANALYSIS_KEYS.MONTHLY);
			this.monthlyCommittedCosts = this.extractCycledAnalysisCategory(this.spendingAnalysis, SPENDING_ANALYSIS_CATEGORIES.FIXED_COSTS, SPENDING_ANALYSIS_KEYS.MONTHLY);
			this.monthlyIncome = this.extractCycledAnalysisCategory(this.spendingAnalysis, SPENDING_ANALYSIS_CATEGORIES.INCOME, SPENDING_ANALYSIS_KEYS.MONTHLY);
			this.monthlyFuturePlans = this.extractCycledAnalysisCategory(this.spendingAnalysis, SPENDING_ANALYSIS_CATEGORIES.FUTURE_PLANNING, SPENDING_ANALYSIS_KEYS.MONTHLY);
		},
		getAnnualAnalysis() {
			this.annualEveryDaySpend = this.extractCycledAnalysisCategory(this.spendingAnalysis, SPENDING_ANALYSIS_CATEGORIES.EVERYDAY_SPEND, SPENDING_ANALYSIS_KEYS.ANNUAL);
			this.annualCommittedCosts = this.extractCycledAnalysisCategory(this.spendingAnalysis, SPENDING_ANALYSIS_CATEGORIES.FIXED_COSTS, SPENDING_ANALYSIS_KEYS.ANNUAL);
			this.annualIncome = this.extractCycledAnalysisCategory(this.spendingAnalysis, SPENDING_ANALYSIS_CATEGORIES.INCOME, SPENDING_ANALYSIS_KEYS.ANNUAL);
			this.annualFuturePlans = this.extractCycledAnalysisCategory(this.spendingAnalysis, SPENDING_ANALYSIS_CATEGORIES.FUTURE_PLANNING, SPENDING_ANALYSIS_KEYS.ANNUAL);
		},
		mergeMonthlyAnnualAnalysis() {
			this.allEveryDaySpend = this._mergeAndSortArrays(this.monthlyEverydaySpend, this.annualEveryDaySpend);
			this.allCommittedCosts = this._mergeAndSortArrays(this.monthlyCommittedCosts, this.annualCommittedCosts);
			this.allFuturePlans = this._mergeAndSortArrays(this.monthlyFuturePlans, this.annualFuturePlans);
			this.allIncome = this._mergeAndSortArrays(this.monthlyIncome, this.annualIncome);
		},
		getSlots() {
			this.months = this.extractCycledAnalysisDates(this.spendingAnalysis, SPENDING_ANALYSIS_KEYS.MONTHLY);
			this.years = this.extractCycledAnalysisDates(this.spendingAnalysis, SPENDING_ANALYSIS_KEYS.ANNUAL);
			this.slots = this._mergeAndSortArrays(this.months, this.years);
		},
		toggleEveryDaySpending(isCollapsed) {
			this.isEveryDaySpendCollapsed = isCollapsed;
		},
		toggleFuturePlans(isCollapsed) {
			this.isFuturePlansCollapsed = isCollapsed;
		},
		toggleCommittedCosts(isCollapsed) {
			this.isCommittedCostsCollapsed = isCollapsed;
		},
		extractCycledAnalysisCategory(spendingAnalysis, categoryName, analysisKey) {
			const result = [];
			const traverse = (obj) => {
				if (obj.grandparentGroupedAnalysis) {
					obj.grandparentGroupedAnalysis.forEach(group => {
						if (group.label === categoryName) {
							if (analysisKey === SPENDING_ANALYSIS_KEYS.MONTHLY) {
								group.slotName = this._getMonthName(group.groupedAnalysis[0].to) + ' ' + this._getYear(group.groupedAnalysis[0].to).toString();
							} else {
								group.slotName = this._getYear(group.groupedAnalysis[0].to).toString();
							}
							result.push(group);
						}
					});
				}
				if (obj[analysisKey]) {
					obj[analysisKey].forEach(cycle => traverse(cycle));
				}
			};
			traverse(spendingAnalysis);
			return result;
		},
		extractCycledAnalysisDates(spendingAnalysis, analysisKey) {
			if (!spendingAnalysis[analysisKey] || !Array.isArray(spendingAnalysis[analysisKey])) {
				return [];
			}

			return spendingAnalysis[analysisKey]
				.map(entry => {
					if (entry.totalIn !== 0 || entry.totalOut !== 0 || entry.totalNet !== 0) {
						var labelString = '';
						var keyString = '';
						if (analysisKey === SPENDING_ANALYSIS_KEYS.MONTHLY) {
							labelString = this._getMonthName(entry.to);
							keyString = this._getMonthName(entry.to) + ' ' + this._getYear(entry.to).toString();
						}
						else {
							labelString = this._getYear(entry.to).toString();
							keyString = this._getYear(entry.to).toString();
						}
						return {
							label: labelString,
							key: keyString,
							from: entry.from,
							to: entry.to
						};
					}
					return null;
				})
				.filter(entry => entry !== null);
		}
	}
};
</script>