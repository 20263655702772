<template>
	<a :href="href != null ? href : $router.path">
		<div class="analysis-widget">
			<v-row>
				<v-col class="v-col-7 title-col">
					Analysis
				</v-col>
				<v-col class="arrow-col">
					<img class="arrow" src="../../assets/arrow-right.png">
				</v-col>
			</v-row>
			<v-row>
				<v-col class="centered" v-if="!analysis">
					<v-progress-circular  indeterminate></v-progress-circular>
				</v-col>
			</v-row>
			<div v-if="analysis">
				<v-row>
					<Bar
						id="spend-chart-id"
						:options="chartOptions"
						:data="chartData"
					/>
				</v-row>
				<v-row>
					<v-col class="centered">In: {{ formatter.format(Math.round(analysis.cycledAnalysis[0].totalIn)).replace('-', '') }}</v-col>
					<v-col class="centered">Out: {{ formatter.format(Math.round(analysis.cycledAnalysis[0].totalOut)).replace('-', '') }}</v-col>
					<v-col class="centered">Pay in: {{ analysis.daysTillCycleResets }}</v-col>
				</v-row>
			</div>
		</div>
	</a>
</template>

<script>

import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
	name: "AnalysisWidget",
	components: { Bar },
	props: ['analysis', 'formatter'],
	data() {
		return {
			chartData: {
				labels: [ 'Income', 'Spend' ],
				datasets: [ 
					{ 
                        backgroundColor: [
							'#23AF64', '#442066'],
						data: [],
						borderRadius: 10
					} 
				],
			},
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
				scales: {
					x: {
                        grid: {
                            display: false 
                        }
                    },					
                    y: {
						grid: {
							display: false 
						},
						ticks: {
                            callback: function(value) {
                                if (value >= 1000) {
                                    return '£' + Math.round(value / 1000) + 'k';
                                }
                                return '£' + value;
                            }
                        }
                    }
                },
				plugins: {
                    legend: {
                        display: false
                    },
                    title: {
                        display: false
                    }
                }
            }
		}	
	},
	watch: {
        analysis: {
            immediate: true,
            handler(newVal) {
                if (newVal && newVal.cycledAnalysis) {
                    this.chartData.datasets[0].data = [
                        Math.round(newVal.cycledAnalysis[0].totalIn),
                        Math.round(newVal.cycledAnalysis[0].totalOut * -1)
                    ]
                }
            }
        }
    },
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.analysis-widget {
	border-radius: 8px;
	padding: 18px;
	border-color: var(--border-color);
	border: 0px solid var(--border-color);
	height: 100%;
	background-color: var(--background-color);
	width: 100%;
}

.title-col {
	font-size: 20px;
}

.arrow-col {
	text-align: right;
	padding-left: 0px;
	padding-top: 20px;
}

.arrow {
	width: 18px;
}


</style>