<template>
	<HeaderBlock :title="bankAccount ? (bankAccount.bank.prettyName ? bankAccount.bank.prettyName : bankAccount.bank.name) : 'Account'" :bgcolor="'#442066'" :color="'white'" :border="false" />
	<CustomerLoadWait :customer="customer && bankAccount" />
	<transition>
		<div v-if="customer && bankAccount">
			<div class="topPanel" style="">
				<BankCard :account="bankAccount" :formatter="formatter" />
			</div>

			<DrawerPanel bottom="44px" title="Recent Transactions" backgroundColor="white" openBackgroundColor="white" closedHeight="52%">
				<TransactionsList search="true" :transactions="bankAccount.groupedTransactions" :formatter="formatter" :today="today" :yesterday="yesterday" />
			</DrawerPanel>

		</div>
	</transition>
</template>

<script>
import Services from '../mixins/Services';
import BankCard from './elements/BankCard.vue';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import DrawerPanel from './elements/DrawerPanel.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import TransactionsList from './elements/TransactionsList.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, BankCard, TransactionsList, HeaderBlock, DrawerPanel},
	data() {
		return {
			section: 'd2d',
			drawerHeight: "82px",
			drawer:false,
			accounts: [],
			accountsBalance: 0,
		}
	},
	methods: {
		toggleTransactions: function() {
			this.drawer = !this.drawer;
			if (!this.drawer)
			{
				this.drawerHeight = "82px";
			}
			else
			{
				this.drawerHeight = "80%";
			}
		},
		getCustomer: function(){
			this._getCustomer(() => {
				
			});
		},
		getBankAccount: function(id){
			this._getBankAccount(id, () => {
			});
		},
		getBankInfo: function(){
			this._getBankingInfo(() => {
				this.bankInfo.accounts.forEach((a) => 
				{
					if (a.accountType.prettyName == "Current" && this.$route.params.accountType.toLowerCase() == "current")
					{
						this.accounts.push(a);
						this.accountsBalance += a.balance;
					}
					else if (a.accountType.prettyName == "Credit" && this.$route.params.accountType.toLowerCase() == "credit")
					{
						this.accounts.push(a);
						this.accountsBalance += a.balance;
					}
					else if (a.accountType.prettyName == "Savings" && this.$route.params.accountType.toLowerCase() == "savings")
					{
						this.accounts.push(a);
						this.accountsBalance += a.balance;
					}
				});
			});
		},
		onLoad: async function() {
			this.getCustomer();
			this.getBankAccount(this.$route.params.id);
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.topPanel {
	padding: 20px; 
	padding-bottom: 36px; 
	padding-top: 140px; 
	margin-left: -30px; 
	margin-right: -30px; 
	margin-top: -138px;
	background: linear-gradient(202deg, #442066 49.85%, #42397b 100%);
}


</style>
