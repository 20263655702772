import Services from '../../mixins/Services';
import CustomerLoadWait from '../elements/CustomerLoadWait.vue';
import AccountButton from '../elements/AccountButton.vue';
import HeaderBlock from '../elements/HeaderBlock.vue';
import BarArrowButton from '../elements/BarArrowButton.vue';
import WellPanel from '../elements/WellPanel.vue';

export default {
	props: ['formatter'],
	mixins: [Services],
	components: { CustomerLoadWait, AccountButton, HeaderBlock, BarArrowButton, WellPanel },
	data() {
		return {
			classificationOptions: [],
			classifications: [],
			selectedClassificationId: null,
			selectedClassification: null,
			snackbar: {
				color: null,
				icon: null,
				mode: null,
				position: "top",
				text: null,
				timeout: 7500,
				title: null,
				visible: false
				},
		}
	},
	computed: {
		camelCaseCategory() {
            if (!this.transaction || !this.transaction.effectiveClassification) return '';
            return this.transaction.effectiveClassification.fullName.toLowerCase().replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
        }
	},
	watch:{
		'selectedClassificationId'  : function (val, oldval) {
			if (oldval && val && val != oldval) {
				this.updateClassification();
			}
		}
	},
	methods: {
		getTransaction: function(id){
			this._getTransaction(id, (t) => {
				this._getBankAccount(t.bankAccountID, () => {}, true);
				this.selectedClassificationId = t.effectiveClassification.id
				this.wellData = [
					{order: 1, label: 'Date', value: this.transaction.timestampFormatted},
					{order: 2, label: 'Account', value: this.bankAccount.bank.name},
					//{order: 3, label: 'Transaction', value: this.transaction.type},
					{order: 4, label: 'Spending Analysis', value: this.transaction.excludeFromAnalysis ? 'Excluded' : 'Included'},
					{order: 5, label: 'Description', value: this.transaction.description},
				];
			})
		},
		fetchClassifications() {
			this._getTransactionClassifications((result) => {
				this.classifications = result;
				this.classificationOptions = result.map(c => ({id: c.mgClassification.id, title: c.mgClassification.fullName}));
			});
		},
		updateClassification: function() {
			this.selectedClassification = this.classifications.find(c => c.mgClassification.id == this.selectedClassificationId);
			this.transaction.effectiveClassification = this.selectedClassification.mgClassification;
			this._updateTransactionClassification(this.transaction, () => {
				this.snackbarShow();
				this.getTransaction(this.transaction.id);
				this._getBankAccount(this.transaction.bankAccountID, null, /* lite: */ false, /*refreshCache: */ true); //Force cache refresh of bank transactions to reflect new classification
			});
		},
		snackbarShow: function() {
			this.snackbar = {
				icon: "success",
				mode: "multi-line",
				timeout: 3000,
				text: "Category updated!",
				visible: true
				};
		},
		onLoad: async function() {
			this.getTransaction(this.$route.params.id);
			this.fetchClassifications();
		}
	},
	mounted: function () {
		this.onLoad();
		this.fetchClassifications();
	},
}