<template>
	<header-block title="Guide to your finances"></header-block>
	<CustomerLoadWait :customer="plans" />
	<transition>
		<div v-if="plans">
			<v-row v-for="p in plans" :key="p.id">
				<v-col>
					<GuideButton :guide="p" />
				</v-col>
			</v-row>
		</div>
	</transition>
	<ChatMgHoverButton v-if="$route.meta.aiChat" prompt="I have just clicked on the help button on the money guided 'guide' screen which has shown this chat window. You are the chat agent for this screen. Do not aknowledge my action, only respond as a guide for the screen."/>
</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import GuideButton from './elements/GuideButton.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import ChatMgHoverButton from './elements/ChatMgHoverButton.vue'

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock, GuideButton, ChatMgHoverButton},
	data() {
		return {
			
		}
	},
	methods: {
		onLoad: async function() {
			this._getCustomer();
			this._getPlans();
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

</style>
