<template>
	<div>
		<v-row class="subCategory">
			<v-col cols="2" class="iconCol">
				<img v-if="!income" :src="iconSrc" width="30" height="30" class="icon" :style="{ background: bgColour }"/>
				<div v-else class="icon-placeholder"></div>
			</v-col>
			<v-col>
				<span class="subCategoryHeadline">{{ group.name }}</span><br/>
				<span v-if="!income && planAmount">Plan: {{ formatter.format(planAmount) }}</span>
				<span v-if="income">Income</span> 
			</v-col>
			<v-col class="right">
				<span v-if="income" class="moneyIn subCategoryHeadline">{{ formatter.format(Math.abs(group.amountIn)) }}</span>
				<span v-if="!income" class="subCategoryHeadline">{{ formatter.format(Math.round(Math.abs(group.amountOut))) }}</span><br/>
				<span v-if="!income && planAmount" class="">{{ planRemainingAmount }}</span> 
			</v-col>
		</v-row>
		<v-row>
			<v-col class="progressCol">
				<v-progress-linear v-if="!income && planAmount"
					height="10"
					:model-value="animatedProgress"
					color="primary"
					rounded
				></v-progress-linear>
			</v-col>
		</v-row>
	</div>
	
</template>

<script>
export default {
	name: "AddButton",
	props: ['formatter', 'group', 'income', 'plans', 'bgColour', 'txtColour', 'isCollapsed'],
	data() {
		return{
			animatedProgress: 0,
		};
	},
	computed: {
		iconSrc() {
			return require(`@/assets/spendCategories/${this.group.transactionClassification.iconPath}`);
		},
		planAmount() {
			if (!this.plans) return null;
			const plan = this.plans.plans.find(plan => plan.classification.id === this.group.transactionClassification.id);
			if (!plan) return null;
			return plan.plan.amount;
		},
		planRemainingAmount() {
			var label = "Remaining: ";
			const amount = this.planAmount - Math.abs(this.group.amountOut);
			if (amount < 0) label = "Overspent: ";
			return label + this.formatter.format(Math.round(Math.abs(this.planAmount - Math.abs(this.group.amountOut))));
		},
		planProgressPercent() {
			return Math.min(100, Math.max(0, Math.round((Math.abs(this.group.amountOut) / this.planAmount) * 100)));
		}
	},
	watch: {
		isCollapsed(isCollapsed) {
			if (!isCollapsed) this.onLoad();
		},
        planProgressPercent(newVal) {
            this.animateProgress(newVal);
        }
    },
	methods: {
		onLoad() {
			this.animateProgress(this.planProgressPercent);
		},
		animateProgress(targetValue) {
			this.animatedProgress = 0;
			const increment = targetValue / 100;
			const interval = setInterval(() => {
				if (this.animatedProgress < targetValue) {
					this.animatedProgress += increment;
				} else {
					this.animatedProgress = targetValue;
					clearInterval(interval);
				}
			}, 10);
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.subCategoryHeadline {
	font-size: 14px;
	font-weight: bold;
	margin-top:2px;
	margin-bottom: -35px;
}

.subCategory {
	padding-top: 18px;
	font-size: 14px;
}

.subCategoryPlan {
	font-size: 12px;
	margin-bottom: -25px;
}

.iconCol {
	padding-top: 19px;
}

.progressCol {
	padding-top: 0px;
	padding-bottom: 0px;
}

.icon {
	border-radius: 50%;
}

.icon-placeholder {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #B4D8D7;
    display: inline-block;
}

:deep(.v-progress-linear__determinate) {
	background: linear-gradient(215.75deg, #9EA2D0 3.1%, #61678D 95.08%);
}

.moneyIn {
	background-color: #23af6433;
	color: #23AF64;
	padding: 3px;
	border-radius: 3px;
	margin-top: 15px;
}

</style>