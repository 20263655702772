<template>
	<v-snackbar transition="fade-transition" class="text-center"
		v-model="snackbar.visible" 
		auto-height 
		:color="'#281C3B'" 
		:multi-line="snackbar.mode === 'multi-line'" 
		:timeout="snackbar.timeout" 
		location="bottom">
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
        <v-layout column>
          <div>{{ snackbar.text }}</div>
        </v-layout>
      </v-layout>
      <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
        <v-icon>clear</v-icon>
      </v-btn>
    </v-snackbar>

	<header-block title="Transaction" class="title"></header-block>
	<CustomerLoadWait :customer="transaction && bankAccount && classificationOptions" :cardBlocks="true" :withTopCard="true" />
	<div v-if="transaction && bankAccount && classificationOptions">
		<div class="transactionHeader">
			<span>{{ transaction.merchantName }}</span>
			<p>
				<span v-if="transaction.amount < 0">
					{{ formatter.format(transaction.amount * -1) }}
				</span>
				<span v-else>
					{{ formatter.format(transaction.amount) }}
				</span>
			</p> 
		</div>
			<v-select class="mg-select"
            v-model="selectedClassificationId"
            :items="classificationOptions"
            append-icon="mdi-chevron-down"
			:list-props="{maxWidth:'250px'}"
			item-value="id"
			/>
		<div style="clear:both; margin-bottom: 30px;"></div>
	</div>
	<div v-if="wellData">
		<WellPanel :data="wellData"/>
	</div>

</template>


<script src="./TransactionPage.js"></script>
<style src="./TransactionPage.css"></style>
