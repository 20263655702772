<template>
	<header-block :title="title" :border="image ? 0 : null" :bgcolor="image ? '#00000000' : 'white'" :color="image ? 'white' : null"></header-block>
	<CustomerLoadWait :customer="availableVouchers && vouchers" :cardBlocks="!filter" />

	<transition>
		<div class="head" v-if="filter && availableVouchers">
			<div class="header" :style="'background-image: url(' + image + ')'">
				<v-row>
					<v-col class="v-col-7 title-col">
						<h1>{{ title }}</h1>
					</v-col>
					<v-col class="v-col-5 chip-col">
						<v-chip color="white" variant="flat">{{ filterCount }} card(s)</v-chip>
					</v-col>
				</v-row>
			</div>
		</div>
	</transition>

	<v-row class="mb-4" v-if="!filter">
		<v-col class="search-col">
			<v-text-field
				ref="search"
				v-model="query"
				prepend-inner-icon="mdi-magnify"
				density="compact"
				label="Search"
				variant="solo"
				:flat="true"
				hide-details
				clearable
				single-line
				bg-color="#eeeeee"
				@click="gotoSearch"
			></v-text-field>
		</v-col>
	</v-row>

	<transition>
		<div v-if="availableVouchers">
			<VoucherList @onFilterApplied="(count) => { filterCount = count }" :categories="filter" :showAll="false" :vouchers="availableVouchers" :search="false" />
		</div>
	</transition>

	<transition>
		<div v-if="!filter && categories && availableVouchers">
			<!-- Parent div to allow odd/even css selectors -->
			<div>
				<div :class="'hubItemWrapper'" v-for="c in this.categories" :key="c.id">
					<CategoryButton @click="navFilter({ title: c.pageTitle, filters: c.providerFilters, image: c.pageImage });"  :image=c.buttonImage :title=c.buttonTitle />
				</div>
			</div>
		</div>
	</transition>
	<div style="clear:both; "></div>
	
</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import VoucherList from './elements/VoucherList.vue';
import CategoryButton from './elements/CategoryButton.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock, VoucherList, CategoryButton},
	data() {
		return {
			filter: this.$route.query.filters ?? null,
			filterCount: 0,
			title: this.$route.query.title ?? "Browse all retail cards",
			image: this.$route.query.image ?? null,
		}
	},
	methods: {
		onLoad: async function() {
			this._getCategories();
			this._getCustomer();
			this._getAvailableVouchers();
			this._getCustomerVouchers();
		},
		navFilter: function(o) {
			this.$router.push({ path: '/vouchers', query: o });
		},
		gotoSearch: function() {
			this.$router.push({ path: '/vouchers/search' });
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.header {
	padding-top: 138px;
	background-size: cover;
	background-position: bottom;
	margin: -20px -30px 0px -30px;
	margin-top: -138px;
	color: white;
	p--adding-bottom: 200px;
	height: 300px;
	padding: 30px;
	padding-top: 200px;
	color: white;
	h1{
		font-size: 28px;
		margin-bottom: 15px;
	}
	margin-bottom: 40px;
}
.title-col {
	padding-top: 0px;
	padding-bottom: 20px;
}
.chip-col {
	text-align: right;
}
.hubItemWrapper {
	margin-top: 12px; 
	margin-bottom: 12px;
	margin-left: 0px; 
	width: 47%;
	float: left !important;
}

.hubItemWrapper:nth-child(even) {
	margin-right: 0px;
	float:right !important;
}
</style>
