<template>

	<header-block title="Guide to your finances"></header-block>
	<CustomerLoadWait :customer="plan" />
	
	<transition>
	<div v-if="plan">
		<h1>{{plan.title}}</h1>
		<h2>{{plan.tagLine}}</h2>
		<p>
			{{plan.description}}
		</p>

		<div class="mt-8 sectionBlocksWrapper anchor-bottom" style="bottom: 170px;">
			<h2>Recommended for you</h2>
			<v-slide-group>
				<div class="sectionBlock" v-for="s in plan.sections" :key="s.id">
					<GuideSectionButton :section="s"></GuideSectionButton>
				</div>
			</v-slide-group>
		</div>
	</div>
	</transition>

	<DrawerPanel openHeight="88%" closedHeight="70px" bottom="0px" backgroundColor="#FBF7F4" openBackgroundColor="white" title="Understanding your money">
		<!-- <v-slide-group class="topMenuRow">
			<v-col class="topMenuCol">
				<v-btn :class="'topTabButton active'" title="" @click="section='d2d'; drawerHeight = '79px'">All</v-btn>
				<v-btn :class="'topTabButton'" title="" @click="section='lt'; drawerHeight = '0px'">Engaging with your money</v-btn>
				<v-btn :class="'topTabButton'" title="" @click="section='ins'; drawerHeight = '0px'">Organise my finances</v-btn>
			</v-col>
			
		</v-slide-group> -->
		<ArticleList :articles="articles"/>
	</DrawerPanel>

</template>

<script>
import Services from '../mixins/Services';
import ArticleList from './elements/ArticleList.vue';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import DrawerPanel from './elements/DrawerPanel.vue';
import GuideSectionButton from './elements/GuideSectionButton.vue';
import HeaderBlock from './elements/HeaderBlock.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock, GuideSectionButton, DrawerPanel, ArticleList},
	data() {
		return {
			guideSections: [ 
				{id: "1234", title: "Engaging with your money", icon: require('@/assets/round1.png'), completed: 1, tasks: 5},
				{id: "1235", title: "Organise my finances", icon: require('@/assets/round2.png'), completed: 0, tasks: 4},
				{id: "1236", title: "Something really cool", icon: require('@/assets/round3.png'), completed: 0, tasks: 4},
			]
		}
	},
	methods: {
		onLoad: async function() {
			this._getCustomer();
			this._getPlan(this.$route.params.id, () => {
				this._getCmsArticles(this.plan.tags);
			})
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.sectionBlock{
	height: 220px; 
	max-width: 230px; 
	margin-right: 10px;
}

.sectionBlocksWrapper {
	padding: 20px; 
	padding-right:0px; 
	border-radius: 10px; 
	background-color: #FBF7F4;
	h2 {
		margin-bottom: 20px;
	}
}

.topTabButton
{
	margin-top: 0px;
	text-transform: none;
	p-adding-top: 17px !important;
	p-adding-bottom: 30px !important;
	vertical-align: middle !important;
	font-weight: bold;
	width: unset !important;
	margin-right: 5px;
}

.topMenuCol {
	padding-left: 3px;
	padding-right: 0px;
	padding-top: 0px;
}

.topMenuRow
{
	margin-left: -5px;
	margin-right: -20px;
	margin-bottom: 15px;
}

</style>
