export const SPENDING_ANALYSIS_CATEGORIES = {
    EVERYDAY_SPEND: 'Everyday spend',
    FIXED_COSTS: 'Fixed costs',
    INCOME: 'Income',
    FUTURE_PLANNING: 'Future planning'
};

export const SPENDING_ANALYSIS_KEYS = {
    MONTHLY: 'cycledAnalysis',
    ANNUAL: 'annualCycledAnalysis'
};

export const GRANDPARENT_CLASSIFICATIONS = {
	INCOME: 0,
	FUTURE_PLANNING: 1,
	EVERYDAY_SPEND: 2,
	FIXED_COSTS: 3
};

export default {

	data() {
		return {
		}
	},

	created: function() {
	},

	methods: {
		_getBeginningOfPreviousYear() {
			const now = new Date();
			const previousYear = now.getFullYear() - 1;
			return new Date(previousYear, 0, 1);
		},
		_mergeAndSortArrays(monthsArray, yearsArray) {
			const mergedArray = [...monthsArray, ...yearsArray];
			mergedArray.sort((a, b) => new Date(a.to) - new Date(b.to));
			return mergedArray;
		},
		_getMonthName(dateString) {
			const date = new Date(dateString);
			return date.toLocaleString('default', { month: 'long' });
		},
		_getYear(dateString) {
			const date = new Date(dateString);
			return date.getFullYear();
		}
	}
  }