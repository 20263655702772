<template>
	<header-block title="" border="0"></header-block>
	<CustomerLoadWait :customer="customerMove" />
	<div class="header" v-if="customerMove">
		<img :src="customerMove.move.backgroundImage" />
	</div>
	<transition>
		<div v-if="customerMove" class="content">

			<h1>{{customerMove.title}}</h1>

			<p>
				{{customerMove.description}}
			</p>

			<p>
				<v-chip class="mt-3" v-if="customerMove.isPending" variant="outlined" size="small">Not started</v-chip>
			</p>

			<div class="anchor-bottom" >
				<v-row class="mt-3">
					<v-col class="v-col-1 timeCol">
						<img :src="require('@/assets/clock.png')">
					</v-col>
					<v-col>
						{{ customerMove.move.timeToComplete }} minutes
					</v-col>
				</v-row>
				<v-btn v-if="customerMove.move.cmsPage" class="mg-button fullWidth" @click="cmsAction">{{ customerMove.move.actionButtonText }}</v-btn>
				<!-- <v-btn v-if="customerMove.move.appScreen" class="mg-button fullWidth" @click="action">{{ customerMove.move.actionButtonText }}</v-btn>
				<v-btn v-if="customerMove.move.websiteUrl" class="mg-button fullWidth" @click="action">{{ customerMove.move.actionButtonText }}</v-btn> -->
				<v-btn v-for="a in customerMove.move.actions.length" :key="a.id" class="mg-button fullWidth" @click="action">{{ customerMove.move.actions[0].cta }}</v-btn>
			</div>

		</div>
	</transition>
</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock},
	data() {
		return {
			
		}
	},
	methods: {
		onLoad: async function() {
			this._getCustomer();
			this._getCustomerMove(this.$route.params.id, () => {
				this._markCustomerMoveAsSeen(this.$route.params.id);
				if (this.customerMove.move.cmsPage) {
					this._getCmsArticleByKey(this.customerMove.move.cmsPage, (article) => {
						this.customerMove.move.cmsPage = article.id;
					});
				}
			});
		},
		action: function(){
			window.location.href = this.planTask.actionUrl;
		},
		cmsAction: function(){
			this._markCustomerMoveAsActioned(this.$route.params.id, () => {

				this.$router.replace('/Articles/' + this.customerMove.move.cmsPage);
			});
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

h1 {
	font-size: 24px;
	margin-bottom: 20px;
}

h2 {
	font-size: 16px;
	margin-bottom: 10px;
}

p {
	font-size: 15px;
}

.header {
	background-color: var(--background-color-alt);
	height: 160px;
	margin-left: -30px;
	margin-right: -30px;
	margin-top: -70px;
	z-index: 200 !important;
	text-align: center;
	position: relative;
	img {
		height: 120px;
		width: 120px;
		border-radius: 50%;
		object-fit: cover;
	}
}

.content {
	padding-top: 30px;
}

.actioned {
	padding: 20px;
	border-radius: 16px;
	background: linear-gradient(64.16deg, rgba(119, 164, 167, 0.2) 18.08%, rgba(170, 206, 205, 0.2) 85.4%);
	.completedCheck {
		float: right;
		margin-top: -16px;
		margin-right: -7px;
	}
}

.mg-button {
	width: 100% !important;
}


</style>
