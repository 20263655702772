<template>

	<header-block title="" :bgcolor="backgroundColor" :color="(theme == 'dark' ? 'white' : '#281C3B')" :border="false">
		<div :class="'progress-container ' + theme">
			<v-progress-linear :model-value="progressValue" rounded></v-progress-linear>
		</div>		
	</header-block>
	<CustomerLoadWait :customer="questionnaire" />

	<transition>
		<div :class="'questionnaire ' + theme">
			<div v-if="questionnaire && answersRetrieved">
				<div v-for="q in questionnaire.questionnaireQuestions" :key="q.id">
					<QuestionnaireQuestion :theme="theme" v-if="currentQuestionId == q.question.id" :formatter="formatter" :question="q.question" @answered="(a) => { answered(a) }" />
				</div>
			</div>
			<div class="submitControls">
				<v-row>
					<v-col cols="4" v-if="allowBack && breadcrumb.length > 0"><v-btn class="mt-5 mg-button outline" @click="goBack()">Back</v-btn></v-col>
					<v-col><v-btn class="mt-5 mg-button" :disabled="!allPageQuestionsAnswered" @click="commitAnswers()">{{nextLabel}}<span class="tick"><img class="arrow" :src="theme == 'light' ? require('@/assets/light-arrow-right.png') : require('@/assets/arrow-right.png')" /></span></v-btn></v-col>
				</v-row>
				<div class="centered"><v-btn class="link mt-5" variant="link" @click="nextQuestion()">Skip</v-btn></div>
			</div>
		</div>
	</transition>

</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import QuestionnaireQuestion from './elements/QuestionnaireQuestion.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock, QuestionnaireQuestion},

	data() {
		return {
			currentQuestionId: null,
			currentQuestionNumber: 0,
			finalQuestionId: null,
			allQuestionIds: [],
			haveAnswer: false,
			allPageQuestionsAnswered: false,
			answersRetrieved: false,
			breadcrumb: [],
			theme: "dark",
			allowBack: false,
			nextLabel: "Continue",
		}
	},

	computed: {
		progressValue() {
			if (this.allQuestionIds.length === 0) return 0;
			return ((this.currentQuestionNumber + 1) / this.allQuestionIds.length) * 100;
		},
		backgroundColor() {
			if (this.$route.meta.backgroundColor){
				return this.$route.meta.backgroundColor;
			}
			if (this.$route.query.theme)
			{
				return this.$route.query.theme === 'dark' ? '#332847' : 'white';
			}
			return this.defaultBackgroundColor;
		}
	},
	
	methods: {

		getQuestionnaire: function(followupQuestionnaireId) {
			var id = followupQuestionnaireId || this.$route.params.id;
			this._getQuestionnaire(id, () => {
				this.currentQuestionId = this.questionnaire.questionnaireQuestions[this.currentQuestionNumber].questionId;
				this.allQuestionIds = this.questionnaire.questionnaireQuestions.map(q => q.questionId);
				this.finalQuestionId = this.allQuestionIds[this.allQuestionIds.length - 1];
				this.selectAlreadyAnsweredSelections(id);
			});
		},

		answered: function(userAnswer){
			console.log(userAnswer, 'caughtFromEmit');
			this.questionnaire.questionnaireQuestions[this.currentQuestionNumber].unsavedAnswer = userAnswer;
			this.allPageQuestionsAnswered = true;
		},

		commitAnswers: function() {
			const question = this.questionnaire.questionnaireQuestions[this.currentQuestionNumber];
			console.log(question.unsavedAnswer, 'unsavedAnswers');
			question.question.selectedAnswer = question.unsavedAnswer;
			console.log(question);
			this._saveQuestionnaireAnswer(question);
			this.nextQuestion();
		},

		nextQuestion: function() {
			this.determineNextQuestion();
		},

		goBack: function() {
			var breadCrumb = this.breadcrumb.pop();
			this.currentQuestionId = breadCrumb.questionId;
			this.currentQuestionNumber = breadCrumb.questionNumber;
		},

		determineNextQuestion: function() {
			this.breadcrumb.push({ questionId: this.currentQuestionId, questionNumber: this.currentQuestionNumber });
			this.currentQuestionNumber++; this.currentQuestionId = this.allQuestionIds[this.currentQuestionNumber];
			if (this.currentQuestionNumber >= this.allQuestionIds.length) {
				const returnRoute = this.$route.query.returnRoute || '/aiop';
				this.$router.push({ path: returnRoute });
				return;
			}
			var conditionalQuestionKey = this.questionnaire.questionnaireQuestions[this.currentQuestionNumber].conditionalQuestionKey;
			var conditionalAnswerValue = this.questionnaire.questionnaireQuestions[this.currentQuestionNumber].conditionalAnswerValue;
			if (!conditionalQuestionKey) return;
			const conditionalQuestion = this.questionnaire.questionnaireQuestions.find(q => q.question.key === conditionalQuestionKey);

			var skipThis = false
			// if this question relies on another answer and that answer is 'wrong'
			// or that answer wasn't answered, skip this question
			if (conditionalQuestion && 
				(!conditionalQuestion.question.selectedAnswer ||
				conditionalQuestion.question.selectedAnswer.length === 0))
			{
				skipThis = true;
			}

			if (conditionalQuestion && 
				(conditionalQuestion.question.selectedAnswer && 
				conditionalQuestion.question.selectedAnswer[0] && 
				conditionalQuestion.question.selectedAnswer[0].answer.value !== conditionalAnswerValue))
			{
				skipThis = true;
			}

			if (skipThis)
			{
				this.currentQuestionNumber++; this.currentQuestionId = this.allQuestionIds[this.currentQuestionNumber];
				if (this.currentQuestionNumber >= this.allQuestionIds.length) {
					const returnRoute = this.$route.query.returnRoute || '/aiop';
					this.$router.push({ path: returnRoute });
					return;
				}
			}

		},

		selectAlreadyAnsweredSelections: function(id) {
			this._getQuestionnaireAnswers(id, (answers) => {
				answers = answers.filter(a => a.isLatest);
				answers.forEach(answer => {
					const question = this.questionnaire.questionnaireQuestions.find(q => q.question.id === answer.question.id);
					if (question) {
						if (answer.answer) { // this is a selected answer, not free
							const selectedAnswer = question.question.possibleAnswers.find(a => a.answerId === answer.answer.id);
							if (selectedAnswer) {
								selectedAnswer.selected = true;
								if (!question.question.selectedAnswer) {
									question.question.selectedAnswer = [];
								}
								question.question.selectedAnswer.push(selectedAnswer);
								question.question.isDefaultValue = true;
							}
						}
						else // this is a free answer
						{
							const freeAmountQuestion = this.questionnaire.questionnaireQuestions.find(q => q.question.id === answer.question.id);
							if (freeAmountQuestion) {
								freeAmountQuestion.question.selectedAnswer = answer.freeAnswer;
								freeAmountQuestion.question.isDefaultValue = true;
							}
						}
					}
				});
				this.answersRetrieved = true;
			});
		},

		onLoad: async function() {
			this._getCustomer();
			this.getQuestionnaire();
			this.theme = this.$route.query.theme || "dark";
			this.allowBack = this.$route.query.allowBack == "true";
			this.$route.meta.backgroundColor = this.theme === "dark" ? "#332847" : "white";
			this.nextLabel = this.$route.query.nextLabel || "Continue";
		}

	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

h1{
	font-size: 24px;
}

.questionnaire {
	color: white;
}

.progress-container {
	display: flex;
	justify-content: center;
	margin: 20px 0;
}

.submitControls {
	position: absolute;
	bottom:20px;
	width: calc(100vw - 60px);
}

.tick {
	text-align: right;
	float: right;
}

.arrow {
	margin-top: 7px;
	height: 12px;
	margin-left: 10px;
}

.link {
	text-transform: none !important;
	text-decoration: underline !important;
}

.light .link {
	color: var(--text-color);
}

.mg-button {
	width: 100% !important;
	background: linear-gradient(243.52deg, #FCE5D4 18.85%, #E0C5C8 81.37%);
	color: var(--text-color) !important;
}

.light .mg-button {
	width: 100% !important;
	background: unset;
	color: white !important;
}

.mg-button.outline{
	background-color: white !important;
	border: 1px solid var(--text-color) !important;
	color: var(--text-color) !important;
}
.light :deep(.v-slider-track__fill) {
  background-color: var(--background-color-alt2) !important;
}
.light :deep(.v-slider-track__background) {
  background-color: var(--border-color) !important;
}
.light .v-progress-linear {
	background: #3328471A !important;
}
.light .v-progress-linear__determinate {
	background: #413655 !important;
}

</style>
