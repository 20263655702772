<template>
<div>
	<v-row class="category" @click="toggleCollapse" :style="{ background: bgColour, color: txtColour }">
		<v-col cols="1" style="text-align: center;">
			<v-icon :class="{ 'rotate-icon': !isCollapsed, 'rotate-back-icon': isCollapsed }">mdi-chevron-right</v-icon>
		</v-col>
		<v-col cols="7" style="text-align: left;">
			{{  categoryName }}
		</v-col>
		<v-col cols="4" style="text-align: right;">
			{{ formatter.format(Math.round(Math.abs(totalAmount))) }}
		</v-col>
	</v-row>
</div>
<v-expand-transition>
	<div v-show="!isCollapsed">
		<slot />
	</div>
</v-expand-transition>
</template>

<script>
export default {
name: "SpendingAnalysisCategory",
props: ['formatter', 'categoryName', 'totalAmount', 'bgColour', 'txtColour'],
data() {
	return{
		isCollapsed: true
	}
},
methods: {
	onLoad: async function() {
	},
	toggleCollapse() {
		this.isCollapsed = !this.isCollapsed;
		this.$emit('toggle', this.isCollapsed);
	}
},
mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.category {
	font-size: 16px;
	font-weight: bold;
	border-radius: 10px !important;
}

.rotate-icon {
	transition: transform 0.3s ease;
	transform: rotate(90deg);
}

.rotate-back-icon {
	transition: transform 0.3s ease;
	transform: rotate(0deg);
}
</style>