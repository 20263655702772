<template>

	<div class="mg-chat-hover">
		<v-btn class="imgButton" variant="text" @click="toggleDrawer">
			<img src="../../assets/ChatMgButton.png" />
		</v-btn>
	</div>

	<transition>
		<div class="bg" v-if="this.drawer" @click="toggleDrawer" />
	</transition>

	<div class="drawer" :style="'height: ' + drawerHeight" @click="toggleDrawer">
		<v-list-item style="text-align: center;">
			<div class="divider"></div>
			<p class="title">Chat MG</p>
		</v-list-item>
		<v-divider></v-divider>
		<div class="messagesCanvas">
			<div v-for="m in messages" :key="m">
				<div :class="'message ' + m.direction">{{m.body}}</div>
			</div>
			<div v-if="typing"><v-progress-linear width="60%" height="1px" indeterminate></v-progress-linear></div>
			<div style="clear:both" ref="end"></div>
		</div>
		<div @click="event => {event.stopPropagation()}">
			<v-text-field v-model="message" variant="flat" class="chatInput" type="text" placeholder="Enter your message" @keypress="onKeyPress" />
		</div>
	</div>
	
</template>

<script>
import Services from '../../mixins/Services';
export default {
	name: "ChatMgHoverButton",
	props: ['prompt'],
	mixins: [Services],
	el: '#app',
	data() {
		return{
			drawer: false,
			drawerHeight: "0px",
			message: null,
			messages:[],
			typing: false
		}
	},
	methods: {
		toggleDrawer: function() {
			this.drawer = !this.drawer;
			if (!this.drawer)
			{
				this.drawerHeight = "0px";
			}
			else
			{
				this.drawerHeight = "80%";
			}
		},
		onKeyPress: function(event) {
			if (event.key === "Enter")
			{
				this.showSentMessage();
				this.sendMessageAndWaitForResponse(this.message);
				this.message = "";
			}
		},
		showSentMessage: function() {
			this.messages.push({direction: "out", body: this.message});
			this.typing = true;
			this.$nextTick(() => {
				if (this.$refs["end"]){
					this.$refs["end"].scrollIntoView({
						behavior: 'smooth',
					})
				}
			});
		},
		sendMessageAndWaitForResponse: function(message, store = true) {
			this.typing = true;
			this._sendChatMessage(message, (res) => { 
				this.typing = false;
				this.messages.push({direction: "in", body: res.body, store: store});
				this.$nextTick(() => {
					if (this.$refs["end"]){
						this.$refs["end"].scrollIntoView({
							behavior: 'smooth',
						})
					}
				});
			});
		},
		onLoad() {
			this._getCustomer(() => {
			if (this.prompt) {
				this.sendMessageAndWaitForResponse(this.prompt, false);
			}});
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

	.title{
		font-weight: bold;
	}

	.mg-chat-hover {
		position: fixed !important;
		bottom: 0 !important;
		right: 0 !important;
		z-index: 0 !important;
	}

	.divider {
		width: 48px;
		margin-bottom: 8px;
		margin-top: 8px;
		margin-right: auto;
		margin-left: auto;
		background: var(--Keyline, rgba(51, 40, 71, 0.10));
		height: 4px;
		border-radius: 4px;
	}

	.imgButton {
		position: fixed !important;
		bottom: 150px !important;
		right: 20px !important;
		z-index: 99999 !important;
		height: 80px !important;
		width: 80px !important;
		border-radius: 50% !important;
	}

	.imgButton img {
		height: 80px !important;
		width: 80px !important;
		border-radius: 50% !important;
	}

	.drawer {
		border-radius: 16px 16px 0px 0px;
		border: 0px;
		overflow-y: hidden;
		background-color: var(--background-color);
		position: fixed;
		bottom: -20px;
		width: 100%;
		left: 0px;
		transition: all .3s ease-out;
		z-index: 1001 !important;
	}

	.chatInput {
		width: 100%;
		height: 50px;
		border: 0px;
		b--order-radius: 16px 16px 0px 0px;
		background-color: var(--background-color);
		color: var(--text-color);
		font-size: 16px;
		padding-left: 16px;
		padding-right: 16px;
		padding-top: 20px;
	}

	.in 
	{
		float: left;
		background: linear-gradient(358.65deg, #F7F4F0 0.56%, #FAF8F5 101.36%);
	}
	
	.out
	{
		float: right;
		o--pacity: 0.8;
		background: linear-gradient(358.65deg, #F7F4F0 0.56%, #FAF8F5 101.36%);
	}

	.message {
		background: linear-gradient(358.65deg, #F7F4F0 0.56%, #FAF8F5 101.36%);
		color: var(--text-color);
		font-size: 16px;
		padding: 16px;
		border-radius: 15px;
		width: 90%;
		margin-bottom: 20px;
		clear:both;
	}

	.messagesCanvas {
		height: calc(100% - 260px);
		overflow-y: scroll;
		padding: 20px;
	}

	.bg {
		z-index: 999 !important;
		position: absolute;
		width: 100vw;
		height: 100vh;
		top: 0px;
		left: 0px;
		background-color: #332847b5;
	}

</style>

<style>
	.v-progress-linear__background {
		background: transparent !important;
	}
</style>