<template>
	<header-block v-if="investmentAccount" :title="investmentAccount.accountType.name"></header-block>
	<CustomerLoadWait :customer="investmentAccount" />
	<transition>
		<div v-if="investmentAccount">
			<WellPanel 
				:title="investmentAccount.provider.name" 
				subTitle="Provider"
				:avatarLetter="investmentAccount.provider.name.charAt(0)"
				:data="wellData"
			/>
		</div>
	</transition>
	<DrawerPanel bottom="-18px" openHeight="450px" title="Saved documents" backgroundColor="white" openBackgroundColor="white">
		<div class="docsPanel" v-if="documents">
			<a v-for="d in documents" :key="d.id" :href="'https://customer-documents-sysodev.s3.eu-west-1.amazonaws.com/' + d.awsKey">
				<v-row>
					<v-col cols="1"><v-icon class="docIcon" icon="mdi-file-document-outline"></v-icon></v-col>
					<v-col class="docCol">{{ d.name }}<br/><span></span></v-col>
					<v-col cols="1" class="arrowCol"><img class="arrow" src="../assets/arrow-right.png" /></v-col>
				</v-row>
			</a>
		</div>
	</DrawerPanel>
</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import DrawerPanel from './elements/DrawerPanel.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import WellPanel from './elements/WellPanel.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock, WellPanel, DrawerPanel},
	data() {
		return {
			policyId: null,
			wellData: []
		}
	},
	methods: {
		onLoad: async function() {
			this.policyId = this.$route.params.id;
			this._getCustomer();
			this._getInvestmentAccount(this.policyId, () => {
				this.wellData = [
					{order: 1, label: 'Type', value: this.investmentAccount.accountType.subType},
					{order: 2, label: 'Value', value: this.formatter.format(this.investmentAccount.balance)},
					{order: 3, label: 'Account number', value: this.investmentAccount.accountNumber},
					{order: 5, label: 'Account details added', value: this.dateFormatPretty.format(new Date(this.investmentAccount.createDateTime))},
				];

				if (this.investmentAccount.accountType.name === 'Pension') {
					this.wellData.push(
						{order: 4, label: 'Retirement age', value: this.investmentAccount.retirementAge},
					);
				}

				if (this.investmentAccount.accountType.name === 'Investment') {
					if (this.investmentAccount.accountType.subType === 'Stocks and shares ISA') {
						this.wellData.push(
							{order: 4, label: 'Tax year contribution', value: this.formatter.format(this.investmentAccount.taxYearAllowanceUsed)},
						);
					}
					else {
						this.wellData.push(
							{order: 4, label: 'Ownership', value: this.jointStatus === 0 ? 'Sole' : 'Joint'},
						);
					}
				}

				this._getDocumentsByHubId(this.investmentAccount.id);
			});
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.docsPanel {
	padding-right: 20px;
}

.docIcon {
	c--olor: var(--grey-text);
}

.docCol {
	font-size: 14px;
	c--olor: var(--grey-text);
}

.arrowCol {
	padding-top: 15px;
}

.arrow {
	width: 18px;
	
}

</style>
