<template>

	<div :class="'question ' + theme">

		<h1>{{ question.text }}</h1>
		<div class="minHeight text">{{ question.subText }}</div>

		<!-- This is a multiple choice question -->
		<div class="multipleChoiceQuestion" v-if="question.questionType == 4">
			<div v-for="a in question.possibleAnswers" :key="a.id">
				<div v-if="!answersHaveIcons" :class="'multipleChoiceListAnswer ' + (a.selected ? 'selected' : '')"  @click="onAnswerSelected(a)">
					<v-row class="answerRow">
						<v-col cols="1" class="radioCol">
							<span class="radio" v-if="a.selected"><img :src="require('@/assets/filledRadio.png')" /></span>
							<span class="radio" v-if="!a.selected"><img :src="require('@/assets/emptyRadio.png')" /></span>
						</v-col>
						<v-col class="answerTextCol"><span class="answerText">{{ a.answer.text }}</span></v-col>
						<v-col cols="1"><span class="tick" v-if="a.selected"><v-icon icon="mdi-check" /></span></v-col>
					</v-row>
					
				</div>
				<div v-else :class="'multipleChoiceGridAnswer ' + (a.selected ? 'selected ' : '') + (question.possibleAnswers.length > 4 ? 'smallGrid' : 'bigGrid')"  @click="onAnswerSelected(a)">
					<v-row class="imgRow">
						<v-col><img :src="require('@/assets/' + a.answer.icon)" /></v-col>
					</v-row>
					<v-row>
						<v-col>{{ a.answer.text }}</v-col>
					</v-row>
				</div>
			</div>
		</div>

		<!-- Money slider -->
		<div class="moneySliderQuestion" v-if="question.questionType == 2">
			<span class="amount">{{ formatter.format(selectedSliderAmount) }}</span>
			<v-slider
				v-model="selectedSliderAmount" 
				:min="minAmount"
				:max="maxAmount"
				:step="stepAmount"
				ticks="always"
				tick-size="4"
				thumb-label="false"
				class="slider mt-5"
			></v-slider>
		</div>

		<div class="text mt-9" v-if="question.description">{{ question.description }}</div>

	</div>
</template>

<script>

export default {
	name: "QuestionnaireQuestion",
	props: ['question', 'formatter', 'theme'],

	data() {
		return{
			selection: [],
			minAmount: 0,
			maxAmount: 10000,
			stepAmount: 100,
			selectedSliderAmount: 0
		}
	},

	computed: {
		answersHaveIcons() {
			if (!this.question || !this.question.possibleAnswers) { return false; }
			return this.question.possibleAnswers.some(a => a.answer.icon);
		}
    },

	watch:{
		'selectedSliderAmount'  : function() {
			this.onSliderAnswerSelected();
		}
	},

	methods: {

		onLoad() {
			console.log('Question control loading');
			console.log(this.question.selectedAnswer, 'selectedAnswer in OnLoad')
			if (this.question.selectedAnswer && this.question.questionType == 2) {
				if (Array.isArray(this.question.selectedAnswer)){
					this.selectedSliderAmount = this.question.selectedAnswer[0].amount;
				} else {
					this.selectedSliderAmount = this.question.selectedAnswer;
				}
			}
			this.question.possibleAnswers.forEach(a => {
				if (a.selected) {
					this.selection.push(...this.question.possibleAnswers.filter(a => a.selected));
					console.log(this.selection, 'controlWillEmit');
					this.$emit('answered', this.selection);
				}
			});
		},

		onSliderAnswerSelected: function() {
			if (this.question.questionType == 2) {
				this.selection = [];
				this.selection.push( { amount: this.selectedSliderAmount } );
				this.$emit('answered', this.selection);
			}
		},

		onAnswerSelected(answer) {
			this.selection = [];
			if (!this.question.multipleChoice) {
				this.question.possibleAnswers.forEach(a => a.selected = false);
			}
			answer.selected = !answer.selected;
			this.selection.push(...this.question.possibleAnswers.filter(a => a.selected));
			this.$emit('answered', this.selection);
		}

	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

	h1{
		font-size: 24px;
	}

	.question {
		margin-top: 15px;
	}

	.question.light {
		color: var(--text-color);
	}

	.text {
		font-size: 15px;
	}

	.minHeight{
		min-height: 22px;
	}

	.multipleChoiceListAnswer {
		margin-top: 15px;
		background-color: #ffffff13;
		padding: 20px;
		border-radius: 4px;
		font-size: 15px;
		answerTextCol {
			text-align: left;
		}
		.answerText {
			display: block;
			float: left;
			margin-left: 14px;
			padding-bottom: 17px;
		}
	}

	.answerRow .v-col{
		padding-bottom: 0px;
		padding-top: 16px;
	}

	.light .answerRow .v-col{
		padding-top: 13px;
	}

	.radioCol {
		display: none;
	}

	.light .multipleChoiceListAnswer {
		background-color: var(--background-color-alt);
		border-radius: 60px;
		height: 48px;
		padding-top: 12px;
	}

	.selected {
		background: linear-gradient(211.44deg, #EEF0FF 17.53%, #D4D9F2 84.89%);
		color: var(--text-color);
	}

	.light .selected {
		background: linear-gradient(211.44deg, #EEF0FF 17.53%, #D4D9F2 84.89%);
	}

	.light .radioCol {
		display: unset;
	}

	.radio img{
		height: 22px;
	}

	.tick {
		text-align: right;
		float: right;
	}

	.light .tick {
		display: none;
	}

	.amount {
		font-size: 30px;
		margin-top: 20px;
	}

	.slider {
		margin-top: 20px;
		color: white;
	}

	.moneySliderQuestion {
		margin-top: 60px;
	}

	.multipleChoiceGridAnswer {
		background-color: #ffffff13;
		float: left;
		padding: 15px;
		width: calc((100vw - (60px + (8px * 4))) / 2);
		height: 200px;
		border-radius: 4px;
		margin-right: 8px;
		margin-left: 8px;
		margin-top: 16px;
		.imgRow {
			text-align: center;
			padding-top: 7px; 
		}
		img {
			height: 64px;
			border-radius: 100%;
		}
		font-size: 15px;
	}

	.bigGrid {
	}	

	.smallGrid {
		font-size: 13px;
		padding: 15px;
		height: 100px;
		img {
			height: 32px;
		}
		.imgRow {
			text-align: left;
			padding-top: 10px;
		}
		.v-col {
			padding-top: 0px;
			padding-bottom: 0px;
		}
	}	

	
</style>