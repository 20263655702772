<template>
	<header-block bgcolor="#FCE5D4aa" title="Retail Cards"></header-block>
	<CustomerLoadWait :customer="customer && vouchers" :cardBlocks="true" :withTopCard="true" />
	<div v-if="customer && vouchers">

		<div class="balanceHeader">
			<span class="bold">Total balance</span>
			<p>{{ formatter.format(balance.toFixed(2)) }}</p>
			<div class="info-header">
				<v-row>
					<v-col class="balance-col">
						<p class="balance-title">Saved to spend</p>
						<div>{{ formatter.format(vouchers.currentSavings) }}</div>
					</v-col>
					<v-col class="balance-col">
						<p class="balance-title">All time saving</p>
						<span>{{ formatter.format(vouchers.allTimeSavings) }}</span>
					</v-col>
				</v-row>	
				<v-row class="button-row">
					<v-col class="button-col">
						<BarArrowButton :icon="require('@/assets/logos/Vouchers.png')" title="Shop all savings" href="/#/vouchers" />
					</v-col>
				</v-row>
			</div>
		</div>

		<!-- Parent div to allow odd/even css selectors -->
		<div>
			<div :class="'hubItemWrapper'" v-for="a in vouchers.vouchers" :key="a.id">
				<AccountButton :href="'/#/myvouchers/' + a.id" :title="a.discount.merchant.name" :icon="a.discount.cardUrl" :balance="formatter.format(a.currentBalance)" :empty="a.currentBalance <= 0"></AccountButton>
			</div>
			<div :class="'hubItemWrapper'">
				<AccountButton href="/#/vouchers" :isAddButton="true"></AccountButton>
			</div>
		</div>


		<div style="clear:both; margin-bottom: 30px;"></div>
		<ChatMgHoverButton prompt="I am on the money guided 'Vouchers' screen which enables me to buy and use Money Guided cashback vouchers. Vouchers are purchased in the 'Show all vouchers' section for their face value and cashback is applied to the 'Saved to spend' pot. You are the chat agent for this screen. Do not aknowledge my action, only respond as a guide for the screen."/>

	</div>
	
</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import AccountButton from './elements/AccountButton.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import BarArrowButton from './elements/BarArrowButton.vue';
import ChatMgHoverButton from './elements/ChatMgHoverButton.vue'

export default {
	mixins: [Services],
	components: { CustomerLoadWait, AccountButton, HeaderBlock, BarArrowButton, ChatMgHoverButton },
	data() {
		return {
			balance: 0
		}
	},
	methods: {
		getCustomer: function(){
			this._getCustomer(() => {
				
			});
		},
		onLoad: async function() {
			this.getCustomer();
			this._getCustomerVouchers(() =>
			{
				this.balance = this.vouchers.vouchers.reduce((a, b) => a + b.currentBalance, 0);
			});
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.title {
	font-size: 14px;
	font-weight: bold;
}

.info-header {
	background-color: #ffffff44;
	border-radius: 16px;
	margin:30px;
	padding: 10px;
	margin-top: 0px;
}

.button-row {
	padding:0px;
}
.button-col {
	padding-top:0px;
}

.balance-col {
	padding-left: 20px;
	font-size: 16px;
	text-align: left;
}

.balance-title {
	font-size: 12px !important;
	margin-bottom: 0px;
	font-weight: lighter;
}

.divider {
	width: 48px;
	margin-bottom: 8px;
	margin-top: 8px;
	margin-right: auto;
	margin-left: auto;
	background: var(--Keyline, rgba(51, 40, 71, 0.10));
	height: 4px;
	border-radius: 4px;
}

.drawer {
	border-radius: 16px 16px 0px 0px;
	border: 0px;
	overflow-y: hidden;
	background-color: var(--background-color);
	position: fixed;
	width: 100%;
	left: 0px;
	transition: all .3s ease-out;
}

.topTabButton
{
	margin-top: 0px;
}

.hubItemWrapper {
	margin-top: 12px; 
	margin-bottom: 12px;
	margin-left: 0px; 
	width: 47%;
	float: left !important;
}

.hubItemWrapper:nth-child(even) {
	margin-right: 0px;
	float:right !important;
}

.topMenuCol {
	padding-left: 5px;
	padding-right: 5px;
}

.topMenuRow
{
	margin-left: -5px;
	margin-right: -5px;
	margin-bottom: 15px;
}

.balanceHeader {
	background: linear-gradient(202deg, #FCE5D4 49.85%, #E0C5C8 100%);
	clear:both;
	margin-left: -30px;
	margin-right: -30px;
	margin-top: -138px;
	margin-bottom: -80px;
	color: var(--text-color);
	font-size: 15px;
	text-align: center;
	padding-top: 15vh;
	padding-bottom: 75px;
	p{
		font-size: 48px;
		font-family: 'Gotham-Heavy';
		margin-top: 0px;
	};
	.small {
		font-size: 10px;
		margin-top: -40px;
	}
}

</style>
