<template>
	<div :class="(large ? 'large ' : ' ') + 'bar-arrow-button ' + (moreRounded ? 'moreRounded ' : ' ')">
		<a :href="href">
			<v-row>
				<v-col v-if="icon && icon != ''" class="icon-col v-col-3">
					<img :src="icon"/>
				</v-col>
				<v-col class="title-col">
					<div :class="'title ' + (bold ? 'bold' : '')" :style="(!icon ? 'padding-left:30px; ' : ' ') + (centerAlign ? 'text-align:center; ' : ' ')">{{ title }}</div>
				</v-col>
				<v-col class="arrow-col v-col-2">
					<img v-show="!hideArrow" class="arrow" src="../../assets/arrow-right.png">
					<img v-show="hideArrow" class="arrow">
				</v-col>
			</v-row>
		</a>
	</div>
</template>

<script>
export default {
	name: "BarArrowButton",
	props: ['icon','title', 'href','bold', 'disabled', 'moreRounded', 'large', 'centerAlign', 'hideArrow'],
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.bar-arrow-button {
	border-radius: 8px;
	padding: 6px;
	border-color: var(--border-color);
	border: 1px solid var(--border-color);
	background-color: var(--background-color);
}

.large {
	padding: 15px !important;
	padding-left: 6px !important;
}

.moreRounded {
	border-radius: 30px;
}

.title-col {
	padding: 0px;
	padding-top: 18px;
}

.large .title-col {
	padding-top: 16px;
}

.title {
	font-size: 12px;
}
.large .title {
	font-size: 15px;
}

.icon-col {
	padding: 0px;
	padding-top: 13px;
	text-align: left;
	padding-left: 15px;
}

.icon-col img {
	height: 30px;
}

.value-title {
	font-size: 10px;
}

.value {
	font-size: 16px;
}

.right-col {
	text-align: center;
	border-left: 1px solid var(--border-color);
}

.arrow-col {
	text-align: right;
	padding-top: 18px;
	padding-right: 18px;
}

.arrow {
	height: 20px;
}

</style>